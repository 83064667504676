import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AiOutlineLink } from "react-icons/ai"

const Projects = () => {
  return (
    <Layout>
      <SEO title="Projects | Yojan Regmi" />

      <div>
        <h1>Projects</h1>
        <div className="projects">
          <div className="project">
            <h3>
              <a href="https://github.com/DevYojan/Javascript30" target="_new">
                Javascript 30
              </a>
            </h3>
            <p>
              30 Javascript projects made in vanilla JS which includes wide
              range of projects such as Custom Video Player, Speech Detection,
              Speech Synthesis etc.
            </p>
            <a href="https://github.com/DevYojan/Javascript30" target="_new">
              <AiOutlineLink /> github
            </a>
            <p className="tools">Javascript, HTML, CSS</p>
          </div>

          <div className="project">
            <h3>
              <a href="https://weatherinfoyojan.netlify.app/" target="_new">
                Weather Info
              </a>
            </h3>
            <p>
              A weather app made in ReactJS that pulls out weather data from the
              openweather API and displays the weather forecast for upcoming 4
              days according to the user location.
            </p>
            <a href="https://github.com/DevYojan/weatherInfo" target="_new">
              <AiOutlineLink /> github
            </a>
            <p className="tools">ReactJS</p>
          </div>

          <div className="project">
            <h3>
              <a href="https://vanillatodo.netlify.app/" target="_new">
                TODO
              </a>
            </h3>
            <p>
              A TODO app that can create, delete and mark as completed todos.
              Also has localstorage.
            </p>
            <a href="https://github.com/DevYojan/TODO" target="_new">
              <AiOutlineLink /> github
            </a>
            <p className="tools">Javascript, HTML, CSS</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Projects
